<template>
  <div class="newsArticle">
    <Banner
      :title="newsArticle.title"
      :subtitle="newsArticle.created"
      :img="bannerImg"
      :isMainPage="false"
      :showDeco="false"
    />
    <div class="container w1400">
      <div class="triangle"></div>
      <img class="bg" src="@/assets/imgs/news/bg_page.png" alt="">
      <div ref="content" class="content w800">
        <!-- 圖片區 -->
        <!-- <img class="image" :src="newsArticle.pic" alt=""> -->
        <div class="image_wrapper">
          <div
            class="image"
            :style="`background-image: url(${newsArticle.pic})`"
          ></div>
        </div>
        <!-- 文字區 -->
        <div class="article" v-html="newsArticle.content"></div>
        <!-- 影片區 -->
        <Video
          v-if="newsArticle.videoUrl"
          :src="newsArticle.videoUrl"
          :height="videoHeight"
        />
        <!--  附件連結 -->
        <a
          v-if="newsArticle.attachment"
          :href="newsArticle.attachment"
          download
          target="_blank"
          class="attachment_link"
        >
          <LinkMore
            class="txt-red link"
            linkText="附件下載"
            fillColor="#d30025"
          />
        </a>
      </div>
      <!-- 返回列表 -->
      <BtnMain
        class="backBtn"
        :text="'BACK'"
        :btnType="3"
        @click.native="$router.push({ name: 'News' })"
      />
    </div>
  </div>
</template>

<script>
import bannerImg from '@/assets/imgs/banner/banner_news_page.png';
import Banner from '@/components/banner/Index.vue';
import Video from '@/components/news/Video.vue';
import LinkMore from '@/components/LinkMore.vue';
import BtnMain from '@/components/BtnMain.vue';

import requestApi from '@/lib/http';
import { formatNews } from '@/utils/formatResponse';

export default {
  name: 'NewsArticle',
  components: {
    Banner,
    Video,
    LinkMore,
    BtnMain,
  },
  data() {
    return {
      bannerImg,
      newsArticle: {},
      videoHeight: '400px',
    };
  },
  methods: {
    async getData() {
      const { uuid: _id } = this.$route.params;

      if (_id) {
        const { entries: data } = await requestApi('common.getNews', {
          filter: {
            _id,
          },
        });
        [this.newsArticle] = data.map(formatNews);
      }
    },
  },
  mounted() {
    this.getData();

    this.$nextTick(() => {
      if (this.$refs.content.clientWidth) this.videoHeight = `${+this.$refs.content.clientWidth * (9 / 16)}px`;
    });
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/helper/_variable.scss';
.newsArticle {
  padding-bottom: 100px;
  background-color: #f6f6f6;

  .container {
    position: relative;
    // margin-top: 16.5%;
    margin-top: 313px;
    background-color: #fff;
  }

  .triangle {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 72px 50px 0;
    border-color: transparent #f6f6f6 transparent transparent;
  }

  .bg {
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 464px;
    height: auto;
  }

  .content {
    // transform: translateY(-10%);
    position: relative;
    padding: 15rem 0 5rem;
  }

  .image_wrapper {
    margin: 0 auto;
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .image {
    width: 100%;
    padding-bottom: 60%;
    background: center/cover no-repeat;
  }

  .article {
    margin: 6% 0;
  }

  .attachment_link {
    margin-top: 5%;
  }

  .backBtn {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
  }

  @media screen and (max-width: 1200px) {
    .content {
      padding: 24% 0 4rem;
    }
  }

  @media screen and (max-width: 1024px) {
    .container {
      margin-top: 280px;
    }
  }

  @media screen and (max-width: 768px) {
    .content {
      padding: 27% 0 3.5rem;
    }
  }

  @media screen and (max-width: 540px) {
    .container {
      margin-top: 200px;
    }
  }

  @media screen and (max-width: 425px) {
    .container {
      margin-top: 180px;
    }
  }

  @media screen and (max-width: 375px) {
    .container {
      margin-top: 160px;
    }
  }
}
</style>
