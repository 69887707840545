<template>
  <iframe
    width="100%" :height="height"
    :src="`https://www.youtube.com/embed/${videoID}`"
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen></iframe>
</template>

<script>
export default {
  name: 'Video',
  props: {
    src: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '400px',
    },
  },
  computed: {
    videoID() {
      return this.src.split('/').pop();
    },
  },
};
</script>
